import commonStyles from '../sass/common.scss';
let commons = require('./utils/commons');
import mapIMG from '../img/map.png';
import gallery1IMG from '../img/gallery-1.png';
import gallery2IMG from '../img/gallery-2.png';
import gallery3IMG from '../img/gallery-3.png';
import gallery4IMG from '../img/gallery-4.png';
import gallery5IMG from '../img/gallery-5.png';
import gallery6IMG from '../img/gallery-6.png';
import painter1IMG from '../img/painter-1.png';
import painter2IMG from '../img/painter-2.png';
import painter3IMG from '../img/painter-3.png';
import painter4IMG from '../img/painter-4.png';
import painter5IMG from '../img/painter-5.png';
import painter6IMG from '../img/painter-6.png';
import painter7IMG from '../img/painter-7.png';
import painter8IMG from '../img/painter-8.png';
import painter9IMG from '../img/painter-9.png';
import painterNoImage from '../img/painter-no-image.png';
import painting1IMG from '../img/painting-1.png';
import painting2IMG from '../img/painting-2.png';
import painting3IMG from '../img/painting-3.png';
import painting4IMG from '../img/painting-4.png';
import painting5IMG from '../img/painting-5.png';
import painting6IMG from '../img/painting-6.png';
import painting7IMG from '../img/painting-7.png';
import painting8IMG from '../img/painting-8.png';
import painting9IMG from '../img/painting-9.png';
import notFoundIMG from '../img/404.png';
